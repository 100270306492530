body,
label,
.checkbox label {
  font-weight: 300;
}
.jqtree-element {
  min-height: 30px;
  /*padding: 3px;*/
  padding: 3px 0;
}
table.dataTable tbody tr.selected {
  background-color: #97BDD6 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#BEE0F5), to(#89AFCA)) !important;
  background: -moz-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  background: -ms-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  background: -o-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7) !important;
}
.testo-righe {
  display: inline-block;
  padding: 5px 2px;
  vertical-align: middle;
}
.testo-righe > .sub-testo-righe {
  margin: 0;
  color: gray;
}
.testo-righe.disabled,
.testo-righe.disabled > .sub-testo-righe {
  color: red;
}
.select2-selection {
  height: 40px !important;
}
.select2-selection__rendered {
  margin-top: 0 !important;
}
.select2-selection__arrow {
  top: 8px !important;
}
